<template>
  <ClientOnly>
    <UIcon v-if="isDark" name="i-heroicons-sun" class="size-5 cursor-pointer duration-200 hover:bg-prim-600" @click="toggleDark()" />
    <UIcon v-else name="i-heroicons-moon" class="size-5 cursor-pointer duration-200 hover:bg-prim-600" @click="toggleDark()" />
  </ClientOnly>
</template>

<script setup lang="ts">
const isDark = useDark()

const toggleDark = useToggle(isDark)

watch(isDark, (newVal) => {
  console.log('isDark', newVal)
})
</script>
